import React from 'react'
import BoxStaticPageList, {
  BoxStaticPageListProps,
} from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'
import { getFullCompany } from '../../../src/common/utils/getCompany'

const sectionsEs: BoxStaticPageListProps[] = [
  {
    title: 'Servicios para Profesionales del Transporte',
    items: [
      {
        title: 'Neumáticos Industriales',
        description: `Ofrecemos un gran stock de neumáticos industriales con las mejores marcas del mercado. En El Paso 2000 encontrarás el mejor precio y un servicio técnico completamente especializado en vehículos industriales.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/camion/neumaticos-camion-elpaso2000.jpg`,
        route: 'camion.neumaticos',
      },
      {
        title: 'Asistencia 24 horas',
        description: `Disponemos de vehículos de asistencia 24h con personal altamente cualificado. Este servicio se encuentra operativo los 365 días del año en toda Europa para flotas de camión. Ofrecemos un precio fijo con el que volver a circular lo antes posible y con la máxima seguridad.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/camion/asistencia-24h-coche-elpaso2000.jpg`,
        route: 'camion.asistencia',
      },
      {
        title: 'Gestión de Flotas',
        description: `Ponemos a disposición de nuestros clientes una web que actúa como herramienta de gestión con la información sobre el estado actual de la flota. Centralizando todos tus vehículos en El Paso 2000 ahorrarás desde el primer hasta el último kilómetro.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/camion/gestion-flotas-camiones-elpaso2000.jpg`,
        route: 'camion.gestion',
      },
      {
        title: 'Acceso a Profesionales',
        description: `En nuestra web encontrarás un apartado dedicado a profesionales donde 
        puedes consultar el estado de toda tu flota de vehículos profesionales. Más información aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/camion/acceso-profesionales-elpaso2000.jpg`,
        external_url: true,
        route: 'https://www.rodi.es/acceso-profesionales/',
        rel: 'nofollow',
      },
    ],
  },
]

const CamionEs = () => (
  <LayoutLanding
    title="Servicios para Camión"
    descriptionCTA="¿Buscas el mejor mantenimiento para vehículos industriales? 
    En El Paso 2000 encontrarás calidad de servicio y una amplia red de talleres a tu disposición. "
    textButtonCTA="LOCALIZA TU TALLER"
    linkButtonCTA="/nuestros-talleres/">
    <React.Fragment>
      <p>
        En <strong>{getFullCompany()}</strong> ofrecemos soluciones para
        profesionales como asistencia en carretera 24h, neumáticos industriales
        y plataforma propia de gestión de flotas. Disponemos de una{' '}
        <strong>amplia red de talleres </strong>presente en las islas canarias
        que ofrece asistencia personalizada a las necesidades de cada cliente.
      </p>
      {sectionsEs.map(section => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

export default CamionEs
